const rules = {
	user: {
		static: [],
	},

	admin: {
		static: [
			"dashboard:view",
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"tickets-manager:notResponse",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"user-modal:editTags",
			"user-modal:editPermissions",
			"ticket-options:deleteTicket",
			"contacts-page:deleteContact",
			"connections-page:actionButtons",
			"connections-page:addConnection",
			"connections-page:editOrDeleteConnection"
		],
	},
	user: {
    static: ["tickets-manager:notResponse"],
  },
};

export default rules;
